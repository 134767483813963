!function($) {
  let adjustVexModalVerticalPositioning = function() {
    let contentHeight = $(this.contentEl).outerHeight()
      , viewportHeight = $(window).height()
      , modalContainer = $(this.rootEl)
      , verticalPadding = (viewportHeight - contentHeight) / 2;

    if(verticalPadding < 60) { verticalPadding = 60; }
    verticalPadding += 'px';
    modalContainer.css({ 'paddingTop': verticalPadding, 'paddingBottom': verticalPadding});
  };

  window.bindVexCloseSelfEvents = function () {
    $(document).on('click', '[data-vex*="close-self"]', function () {
      vex.closeTop();
    });
  };
  window.adjustVexModalVerticalPositioning = adjustVexModalVerticalPositioning;
  bindVexCloseSelfEvents();
}(jQuery);
